import React from 'react'
import { Router, Redirect } from '@reach/router'

export default function Homepage({ location }) {
	return (
		<Router>
			<Redirect from='/' to='/app' noThrow />
		</Router>
	)
}
